import React from "react";
import { useRouter } from "next/router";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { appWithTranslation } from "next-i18next";
import CFError from "../components/invoice/error/CFError";
import AFError from "../components/invoice/error/AFError";

const Custom404 = () => {
  const { asPath: currentPath } = useRouter();
  return <>{currentPath.startsWith("/order/") ? <CFError /> : <AFError />}</>;
};

export default Custom404;

export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
